<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper mt-4">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12">
            <div class="card p-md-2 p-0">
              <div class="card-body pt-3 pb-5">
                <div class="row justify-content-center">
                  <div class="col-xl-8">
                    <h4 class="mb-3">Update Technician Commission Payment Settings</h4>
                    <div class="d-flex w-100 align-items-end mb-4">
                      <div class="w-100 mr-1">
                        <label for="">{{(technicianCommissionPaymentReferencePrefix.key.replaceAll("_", " ").replace("technician", ""))}}</label>
                        <input type="text" class="form-control" placeholder="" v-model="technicianCommissionPaymentReferencePrefix.value">
                      </div>
                      <div>
                        <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updateTechnicianCommissionPaymentPrefixSetting">Update</button>
                      </div>
                    </div>
                    <div class="d-flex w-100 align-items-end">
                      <div class="w-100 mr-1">
                        <label for="">{{(technicianCommissionPaymentReferenceSuffix.key.replaceAll("_", " ").replace("technician", ""))}}</label>
                        <input type="text" class="form-control" placeholder="" v-model="technicianCommissionPaymentReferenceSuffix.value">
                      </div>
                      <div>
                        <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updateTechnicianCommissionPaymentSuffixSetting">Update</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// appLayout
import AppLayout from '../../../../layouts/backEnd/AppLayout.vue'

// mixins
import ShowToastMessage from '@/components/backEnd/mixins/ShowToastMessage';
import Loader from '@/components/backEnd/mixins/Loader';
import Authorization from '@/components/backEnd/mixins/Authorization';
// core packages
import {
  mapActions, mapGetters,
} from "vuex";

export default {
  name: 'TechnicianCommissionPayment',
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
  },
  data() {
    return {
      getSettingsParams: {
        type: ['technician_commission_payment'],
      },
      technicianCommissionPaymentReferencePrefix:
          {
            id: '',
            key:'',
            type:'technician_commission_payment',
            value:''
          }
      ,
      technicianCommissionPaymentReferenceSuffix:
          {
            id: '',
            key:'',
            type:'technician_commission_payment',
            value:''
          }
      ,
    };
  },

  computed: {
    ...mapGetters({
      settingTechnicianCommissionPaymentReferencePrefix:"appSettings/settingTechnicianCommissionPaymentReferencePrefix",
      settingTechnicianCommissionPaymentReferenceSuffix:"appSettings/settingTechnicianCommissionPaymentReferenceSuffix",
    }),
  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",
    }),

    async getSettingList() {
      this.loader(true);
      const response = await this.getSettings(this.getSettingsParams);
      this.loader(false);
      if(response.status === 200){
        this.technicianCommissionPaymentReferencePrefix = this.settingTechnicianCommissionPaymentReferencePrefix;
        this.technicianCommissionPaymentReferenceSuffix = this.settingTechnicianCommissionPaymentReferenceSuffix;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    },

    //update prefix
    prefixValidationChecker(){
      if(!this.technicianCommissionPaymentReferencePrefix.value){
        this.showToastMessage({
          type:'error',
        });
      }
    },
    async updateTechnicianCommissionPaymentPrefixSetting() {
      if(!this.prefixValidationChecker()){
        const data = {
          type: this.technicianCommissionPaymentReferencePrefix.type,
          key: this.technicianCommissionPaymentReferencePrefix.key,
          value: this.technicianCommissionPaymentReferencePrefix.value,
        }


        const dataObj = {
          id: this.technicianCommissionPaymentReferencePrefix.id,
          data: data
        }

        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200 ) {
          this.showToastMessage({
            type:'success',
            message:`  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      }

    },

    //update suffix
    suffixValidationChecker(){
      if(!this.technicianCommissionPaymentReferenceSuffix.value){
        this.showToastMessage({
          type:'error',
        });
      }
    },
    async updateTechnicianCommissionPaymentSuffixSetting() {
      if(!this.suffixValidationChecker()){

        const data = {
          type: this.technicianCommissionPaymentReferenceSuffix.type,
          key: this.technicianCommissionPaymentReferenceSuffix.key,
          value: this.technicianCommissionPaymentReferenceSuffix.value,
        }


        const dataObj = {
          id: this.technicianCommissionPaymentReferenceSuffix.id,
          data: data
        }

        this.loader(true);
        const response = await this.putSetting(dataObj);
        this.loader(false);
        if (response.status === 200 ) {

          this.showToastMessage({
            type:'success',
            message:`  Updated Successfully  `
          });
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      }
    }
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    this.loader(false);
  },
}
</script>

<style scoped>
</style>